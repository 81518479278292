import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Checkbox, Form, Grid, Segment, TextArea} from 'semantic-ui-react';
import FormField from "../formField";
import VehicleTable from "./vehicleTable";
import Documents from "../shared/documents";
import {useDispatch, useSelector} from "react-redux";
import {columnsTypesConfigSelector} from "../../../../ducks/representations";
import {REGISTRATION_TC_GRID} from "../../../../constants/grids";
import {documentsTemplatesSelector, downloadDocumentRequest} from "../../../../ducks/documents";
import {configRegTCSelector} from "../../../../ducks/gridCard";
import {SETTINGS_TYPE_EDIT} from "../../../../constants/formTypes";
import {personalDataRequest, templatePersonalDataSelector} from "../../../../ducks/reports";
import {commentsToText} from "../../../../utils/comments";
import {REG_TC_FILE_FORMATS} from "../../../../constants/settings";

const Information = ({form = {}, onChange, settings = {}, error, updateHistory, isRegTC}) => {
    const {t} = useTranslation();
    const isResidentRF = form.isResident;
    const documents = useSelector(state => documentsTemplatesSelector(state, 'Shared', 'companyOPF'));
    const docTC = (isResidentRF && documents.length) ? documents[0] : null;
    const columnsConfig = useSelector(state => !isRegTC ? columnsTypesConfigSelector(state, REGISTRATION_TC_GRID): configRegTCSelector(state));

    const dispatch = useDispatch();
    const handleDownload = id => {
        dispatch(downloadDocumentRequest({id}));
    };

    useEffect(()=>{
        dispatch(personalDataRequest());
    }, []);

    const url = useSelector(state => templatePersonalDataSelector(state));

    return (
        <Form className="tabs-card">
            <Grid>
                <Grid.Row columns={2}>
                    <Grid.Column width="8">
                        <FormField
                            name="email"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>

                    <Grid.Column width="8">
                        <Form.Field>
                            <FormField
                                name="isResident"
                                columnsConfig={columnsConfig}
                                settings={settings}
                                form={form}
                                error={error}
                                onChange={onChange}
                            />
                        </Form.Field>
                    </Grid.Column>

                </Grid.Row>

                <Grid.Row>
                    <Grid.Column width="8">
                        <Form.Field>
                            <FormField
                                name="lastComment"
                                label='comments'
                                placeholder={t('rtcCommentPlaceholder')}
                                columnsConfig={columnsConfig}
                                settings={settings}
                                form={form}
                                error={error}
                                onChange={onChange}
                            />
                            <label>{t('rtcCommentsHistory')}</label>
                            <TextArea
                                className='textarea-disabled-scroll'
                                disabled
                                autoHeight
                                name='comments'
                                value={commentsToText(form.comments)}
                            />
                        </Form.Field>

                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <Segment>
                                <label className='margin-bottom-10'>{t('transportCompany')}</label>
                                <Grid>
                                    <Grid.Row columns={3} className='tabs-card__fields'>
                                        {isResidentRF && <Grid.Column>
                                            <FormField
                                                name="companyOPF"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>}
                                        <Grid.Column>
                                            <FormField
                                                name="companyName"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        {isResidentRF && <Grid.Column>
                                            <FormField
                                                name="companyINN"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>}
                                        {isResidentRF && <Grid.Column>
                                            <FormField
                                                name="companyOGRN"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>}
                                        {!isResidentRF && <Grid.Column>
                                            <FormField
                                                name="companyRegistrationNumber"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>}
                                        {isResidentRF && <Grid.Column>
                                            <FormField
                                                name="companyBaseLocationCity"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>}
                                        <Grid.Column>
                                            <FormField
                                                name="companyLegalAddress"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="companyFactAddress"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <Segment>
                                <label className='margin-bottom-10'>{t('managerToWorkWithFM')}</label>
                                <Grid>
                                    <Grid.Row columns={3} className='tabs-card__fields'>
                                        <Grid.Column>
                                            <FormField
                                                name="managerLastName"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="managerFirstName"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        {isResidentRF && <Grid.Column>
                                            <FormField
                                                name="managerPatronymic"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>}
                                        <Grid.Column>
                                            <FormField
                                                name="managerPhoneNumber"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="managerEmail"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <br/>
                                <label className='margin-bottom-10'>{t('generalManager')}</label>
                                <Grid>
                                    <Grid.Row columns={3} className='tabs-card__fields'>
                                        <Grid.Column>
                                            <FormField
                                                name="generalManagerLastName"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="generalManagerFirstName"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        {isResidentRF && <Grid.Column>
                                            <FormField
                                                name="generalManagerPatronymic"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>}
                                        <Grid.Column>
                                            <FormField
                                                name="generalManagerPhoneNumber"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="generalManagerEmail"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <FormField
                                name="transportationDirections"
                                columnsConfig={columnsConfig}
                                settings={settings}
                                form={form}
                                error={error}
                                onChange={onChange}
                            />
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <Segment>
                                <label className='margin-bottom-10'>{t('selfVehicleParks')}</label>
                                <VehicleTable form={form} nameInForm={'selfVehicleParks'} onChange={onChange} error={error} columnsConfig={columnsConfig} settings={settings} />
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <Segment>
                                <label className='margin-bottom-10'>{t('rentedVehicleParks')}</label>
                                <VehicleTable form={form} nameInForm={'rentedVehicleParks'} isRented
                                              onChange={onChange} error={error} columnsConfig={columnsConfig} settings={settings} />
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <FormField
                            name="isDangerous"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    {docTC && <Grid.Column>
                        <Segment>
                            <label className='margin-bottom-10'>{docTC.name}</label>
                            <Documents
                                formats={REG_TC_FILE_FORMATS}
                                gridName={REGISTRATION_TC_GRID}
                                cardId={form.id}
                                typeId={docTC.typeId}
                                isEditPermissions={settings && settings['vehicleRegCertificate'] === SETTINGS_TYPE_EDIT}
                                updateHistory={updateHistory}
                            />
                        </Segment>
                    </Grid.Column>}
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <div className='grid-card-header ui checkbox'>
                            <Checkbox
                                name="isAcceptPersonalDataPolicy"
                                onChange={(e, {checked}) => onChange(e, {name: 'isAcceptPersonalDataPolicy', value: checked})}
                                checked={form.isAcceptPersonalDataPolicy}
                                disabled={form.state !== 'rtcDraft' || !isRegTC}
                            />
                            <div className='ui checkbox'>{t('consentTo')} <a onClick={()=>handleDownload(url.previousGenericReportTemplateId)} className='docs-download_link'>{t('processingOfPersonalData')}</a> *</div>
                        </div>
                    </Grid.Column>

                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default Information;
