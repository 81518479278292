import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {Button, Confirm, Dropdown, Icon as IconS, Loader, Popup} from 'semantic-ui-react';
import {
    cardSelector,
    clearGridCard,
    defaultFormDataSelector,
    deleteDraftRegTCRequest,
    editCardRequest,
    editProgressSelector,
    errorSelector,
    getCardRequest,
    getDefaultFormData,
    getRegTcConfig,
    isUniqueNumberRequest,
    progressSelector,
    regTCRequest,
    settingsFormSelector,
} from '../../ducks/gridCard';
import {
    actionsCardSelector,
    clearActions,
    getActionsRequest,
    invokeActionRequest,
    progressActionNameSelector,
} from '../../ducks/gridActions';
import {
    FREE_TRANSPORTS_GRID,
    ORDERS_GRID,
    QUOTATIONS_GRID,
    REGISTRATION_TC_GRID,
    SHIPPINGS_GRID
} from '../../constants/grids';
import ShippingCard from './components/shippingCard';
import {
    DICTIONARY_CARD_LINK,
    DICTIONARY_NEW_LINK,
    GRID_CARD_LINK, LOGIN_LINK,
    TC_FORM_NO_LOGGED_LINK
} from '../../router/links';
import {clearHistory, getHistoryRequest} from '../../ducks/history';
import {exportProgressSelector} from "../../ducks/autogrouping";
import {generateReportRequest, printLoaderSelector} from "../../ducks/reports";
import RegistrationTCCard from "./components/regTransportCompanyCard";
import {isAuthSelector} from "../../ducks/login";
import {downloadAllDocuments, downloadFilesProgressSelector} from "../../ducks/documents";
import PhoneNumberModal from "./components/shippingTabs/phoneNumberModal";
import PaymentTermModal from "./components/shippingTabs/paymentTermModal";
import FreeTransportCard from "./components/freeTransportCard";
import DeliveryTypeModal from "./components/shippingTabs/deliveryTypeModal";
import {goBack} from "../../utils/urlParamsHelper";
import GridCard from "./components/gridCard";
import ShippingCardCreate from "./components/shippingTabsCreate/shippingCardCreate";
import {arrayToColumnsConfig} from "../../utils/sort";
import {cargoUnitsFields} from "../../constants/stoppers";
import FormTable from "../../components/Form/FormTable";
import {SETTINGS_TYPE_EDIT} from "../../constants/formTypes";
import ClosedAuctionModal from "./components/shippingTabs/closedAuctionModal";

const Card = props => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {match, history, location = {}} = props;
    const {state: locationState = {}} = location;
    const isRegTC = match.path === TC_FORM_NO_LOGGED_LINK;
    const {params = {}} = !isRegTC ? match : {params: {name: REGISTRATION_TC_GRID, id: match.params.id}};
    const {name, id} = params;
    const scrollTo = location.hash.replace('#', '');

    useEffect(()=>{
        isRegTC && dispatch(getRegTcConfig());
    }, []);

    let [form, setForm] = useState({});
    let [notChangeForm, setNotChangeForm] = useState(true);
    let [confirmation, setConfirmation] = useState({open: false});

    const nameNumber = (name, form) => {
        switch (name) {
            case REGISTRATION_TC_GRID :
            case QUOTATIONS_GRID :
                return form.number;
            case ORDERS_GRID :
                return form.orderNumber;
            default:
                return form.shippingNumber;
        }
    }

    const title = useMemo(
        () =>
            id
                ? t(`edit_${name}`, {
                    number: nameNumber(name, form),
                    status: t(form.status || form.state),
                    interpolation: { escapeValue: false }
                })
                : t(`new_${name}`),
        [name, id, form],
    );

    const card = useSelector(state => cardSelector(state));
    const settings = useSelector(state => settingsFormSelector(state, card.status || card.state));
    const error = useSelector(state => errorSelector(state));
    const exportLoader = useSelector(state => exportProgressSelector(state));
    const printLoader = useSelector(state => printLoaderSelector(state));
    const downloadFilesProgress = useSelector(state => downloadFilesProgressSelector(state));
    const isAuth = useSelector(state => isAuthSelector(state));

    const defaultForm = useSelector(state => defaultFormDataSelector(state));
    const isCreate = ([SHIPPINGS_GRID, FREE_TRANSPORTS_GRID, QUOTATIONS_GRID].includes(name) && !id);

    useEffect(() => {
        (isCreate && defaultForm) && setForm(form => ({...form, ...defaultForm}));
    }, [defaultForm]);

    useEffect(() => {
        if (locationState.form) {
            setForm(locationState.form)
        } else {
            isCreate && dispatch(getDefaultFormData(name));
        }

        if (locationState.lookup && locationState.name) {
            onChangeForm(null, {
                name: locationState.name,
                value: locationState.lookup
            });
            if (locationState.autocompleteItems)
                locationState.autocompleteItems.forEach(value => {
                    onChangeForm(null, {
                        name: value.name,
                        value: locationState.lookup[value.nameValue]
                    });
                    if (value.dependentValueName && locationState.lookup[value.nameValue] === value.dependentValue)
                        onChangeForm(null, {
                            name: value.dependentValueName,
                            value: value.dependentValue
                        });
                });
            setNotChangeForm(false);
        }

    }, [location]);

    useEffect(() => {
        !locationState.form && setForm({
            ...card,
        });
    }, [card]);

    useEffect(() => {
        dispatch(clearActions());
        id && loadCard();

        return () => {
            dispatch(clearHistory());
            dispatch(clearGridCard());
        };
    }, [id]);

    useEffect(
        () => {
            if (notChangeForm) {
                Object.keys(form).forEach(key => {
                    if (form[key] !== card[key]) {
                        setNotChangeForm(false);
                    }
                });
            }
        },
        [form],
    );

    const dropLocationState = () => {
        history.push({
            state: {
                autocompleteItems: null,
                name: null,
                form: null
            },
        });
    };

    const loadCard = (notChange = false) => {
        id && dispatch(
            getCardRequest({
                isRegTC,
                name,
                id,
                callbackSuccess: () => {
                    setNotChangeForm(!notChange ? !locationState.form : true);
                },
                redirectCallback: onClose,
            }),
        );
        id && dispatch(
            getActionsRequest({
                name,
                ids: [id],
                isCard: true,
                isRegTC
            }),
        );
        id && !isRegTC && getHistory();
    };

    const getHistory = () => {
        dispatch(getHistoryRequest({id, isRegTC}));
    };

    const onClose = () => {
        const {state = {}} = location;
        const {pathname: pn, gridLocation} = state;
        const {path, params} = match;
        isRegTC && form.state === "rtcDraft" && dispatch(deleteDraftRegTCRequest(form.id));
        const pathname = pn ? pn : ((path && params) ? path.replace(':name', params.name).replace('/:id', '').replace('/new', '') : null);
        (pathname && isAuth) ? history.replace({
            pathname: pathname,
            state: {
                ...state,
                pathname: gridLocation,
            },
        }) : (
            isAuth
                ? goBack(history, location, id)
                : history.replace(LOGIN_LINK)
        );
    };

    const handleClose = isConfirm => {
        if (!isConfirm || notChangeForm) {
            onClose();
        } else {
            showConfirmation(
                t('confirm_close_dictionary'),
                () => {
                    closeConfirmation();
                    onClose();
                },
                () => {
                    closeConfirmation();
                },
            );
        }
    };

    const onChangeForm = useCallback((e, {name, value}) => {
        setForm(prevState => ({
            ...prevState,
            [name]: value,
        }));
    }, []);

    const saveOrEditForm = (callbackFun) => {
        locationState.form && dropLocationState();
        const formData = {
            isCreate,
            name,
            params: form,
            callbackSuccess: callbackFun ? callbackFun : (result) => {
                const actionTryToRun = (result || {}).actionTryToRun;
                if (form.id) {
                    if (actionTryToRun) {
                        invokeAction(actionTryToRun)
                    } else {
                        history.replace(location.pathname);
                        loadCard();
                    }
                } else {
                    history.replace(GRID_CARD_LINK.replace(':name', name).replace(':id', result.id));
                }
            },
        }
        dispatch(isRegTC ? regTCRequest(formData) : editCardRequest(formData));
    };

    const handleSave = () => {
        if (name === ORDERS_GRID && !form.id) {
            handleUniquenessCheck(saveOrEditForm);
        } else {
            saveOrEditForm();
        }
    };

    const closeConfirmation = () => {
        setConfirmation({
            open: false,
        });
    };

    const showConfirmation = (content, onConfirm, onCancel) => {
        setConfirmation({
            open: true,
            content,
            onConfirm,
            onCancel,
        });
    };

    const invokeAction = actionName => {
        showConfirmation(
            `${t('Are you sure to complete')} "${t(actionName)}"?`,
            () => {
                closeConfirmation();
                dispatch(
                    invokeActionRequest({
                        isRegTC: isRegTC,
                        ids: [id],
                        name,
                        actionName,
                        redirectCallback: onClose,
                        callbackSuccess: () => {
                            if (actionName.toLowerCase().includes('delete')) {
                                onClose();
                            } else {
                                loadCard(true);
                            }
                        },
                    }),
                );
            },
            closeConfirmation,
        );
    };

    const handleUniquenessCheck = callbackFunc => {
        dispatch(
            isUniqueNumberRequest({
                number: form.orderNumber,
                fieldName: 'orderNumber',
                errorText: t('number_already_exists'),
                callbackSuccess: callbackFunc,
            }),
        );
    };

    const loading = useSelector(state => progressSelector(state));
    const editLoading = useSelector(state => editProgressSelector(state));
    const actions = useSelector(state => actionsCardSelector(state));
    const progressActionName = useSelector(state => progressActionNameSelector(state));
    const disableSave = (name === REGISTRATION_TC_GRID && !form.isAcceptPersonalDataPolicy) || ((!form.isSaveButtonForceEnabled) && (progressActionName || notChangeForm || !(form.isSaveButtonEnabled || isRegTC || isCreate)));

    const goToSoldTo = (id, params) => {
        const go = () => {
            const {state} = location;
            history.replace({
                pathname: id ? DICTIONARY_CARD_LINK.replace(':name', 'warehouses').replace(':id', id) : DICTIONARY_NEW_LINK.replace(':name', 'warehouses'),
                state: {
                    ...state,
                    pathname: history.location.pathname,
                    gridLocation: state.gridLocation ? state.gridLocation : state.pathname,
                    ...params
                },
            });
        };

        if (notChangeForm) {
            go()
        } else {
            showConfirmation(
                t('confirm_close'),
                () => {
                    closeConfirmation();
                    saveOrEditForm(go);
                },
                () => {
                    closeConfirmation();
                },
            );
        }
    };

    const getActionsFooter = useCallback(
        () => {
            return (
                <>
                    <Button color="grey" onClick={handleClose}>
                        {t('CancelButton')}
                    </Button>
                    <Button
                        color="blue"
                        disabled={disableSave}
                        loading={editLoading}
                        onClick={handleSave}
                    >
                        {t('SaveButton')}
                    </Button>
                </>
            );
        },
        [form, disableSave, editLoading, name],
    );

    const goToCard = (gridName, cardId) => {
        const {state} = location;
        history.replace({
            pathname: GRID_CARD_LINK.replace(':name', gridName).replace(':id', cardId),
            state: {
                ...state,
                pathname: history.location.pathname,
                gridLocation: state.gridLocation ? state.gridLocation : state.pathname,
            },
        });
    };

    const getActionsHeader = useCallback(
        () => {
            return (
                <div
                    className="grid-card-header"
                >
                    {name === ORDERS_GRID && form.shippingId ? (
                        <div className="link-cell" onClick={() => goToCard(SHIPPINGS_GRID, form.shippingId)}>
                            {t('open_shipping', {number: form.shippingNumber})}
                        </div>
                    ) : null}
                    {[SHIPPINGS_GRID].includes(name) &&
                            <Dropdown
                                className="grid-print-btn"
                                pointing="top right"
                                trigger={
                                    <Popup
                                        content={t('printReport')}
                                        position="bottom right"
                                        trigger={
                                            <Button
                                                icon
                                                loading={printLoader}
                                            >
                                                <IconS name="print"/>
                                            </Button>
                                        }
                                    />
                                }
                                value={null}
                                selectOnBlur={false}
                            >
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={printReport} key={0} value={0}>{t('genericReport')}</Dropdown.Item>
                                    <Dropdown.Item onClick={printReport} key={1} value={1}>{t('forwardingReport')}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                    }

                    {name === REGISTRATION_TC_GRID && !isRegTC &&
                        <Popup
                            content={t('download-docs')}
                            position="bottom right"
                            trigger={
                                <Button
                                    icon={'download'}
                                    loading={downloadFilesProgress}
                                    onClick={downloadFiles}
                                    disabled={downloadFilesProgress || !form.isAllDocsDownload}
                                />
                            }
                        />
                    }

                    {name === SHIPPINGS_GRID && form.orders && form.orders.length ? (
                        <Dropdown
                            text={t('orders')}
                            pointing="top right"
                            className="dropdown-blue"
                            scrolling
                        >
                            <Dropdown.Menu>
                                {form.orders.map(order => (
                                    <Dropdown.Item
                                        className="link-cell"
                                        key={order.id}
                                        text={order.orderNumber}
                                        onClick={() => {
                                            goToCard(ORDERS_GRID, order.id);
                                        }}
                                    />
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    ) : null}

                    {![FREE_TRANSPORTS_GRID].includes(name) && <Dropdown
                        icon="ellipsis horizontal"
                        floating
                        button
                        pointing="top right"
                        className="icon"
                        scrolling
                    >
                        <Dropdown.Menu>
                            {actions &&
                                actions.filter(item => item.allowedFromForm).map(action => (
                                    <Dropdown.Item
                                        key={action.name}
                                        text={t(action.name)}
                                        label={{
                                            color: action.color,
                                            empty: true,
                                            circular: true,
                                        }}
                                        onClick={() => invokeAction(action.name)}
                                    />
                                ))}
                        </Dropdown.Menu>
                    </Dropdown>}
                </div>
            );
        },
        [form, actions, name, printLoader, downloadFilesProgress],
    );

    const printReport = (e, {value}) => {
        dispatch(generateReportRequest({
            gridName: name,
            params: {
                type: value,
                isGrid: false,
                ids: [id]
            }
        }))
    };

    const downloadFiles = () => {
        dispatch(downloadAllDocuments({
            id: form.id
        }))
    }

    const typeCard = (name) => {
        switch (name) {
            case SHIPPINGS_GRID :
                return (
                    id ? <ShippingCard
                        {...props}
                        title={title}
                        id={id}
                        name={name}
                        form={form}
                        load={loadCard}
                        updateHistory={getHistory}
                        loading={loading}
                        settings={settings}
                        error={error}
                        onClose={handleClose}
                        onChangeForm={onChangeForm}
                        actionsFooter={getActionsFooter}
                        actionsHeader={getActionsHeader}
                        saveForm={saveOrEditForm}
                        scrollTo={scrollTo}
                    /> : <ShippingCardCreate
                        {...props}
                        title={title}
                        gridName={name}
                        form={form}
                        load={loadCard}
                        loading={loading}
                        settings={settings}
                        errors={error}
                        onClose={handleClose}
                        onChangeForm={onChangeForm}
                        actionsFooter={getActionsFooter}
                    />
                )
            case REGISTRATION_TC_GRID :
                return (
                    <RegistrationTCCard
                        {...props}
                        title={title}
                        id={id}
                        name={name}
                        form={form}
                        load={loadCard}
                        updateHistory={getHistory}
                        loading={loading}
                        settings={settings}
                        error={error}
                        onClose={handleClose}
                        onChangeForm={onChangeForm}
                        actionsFooter={getActionsFooter}
                        actionsHeader={getActionsHeader}
                        saveForm={saveOrEditForm}
                        isRegTC={isRegTC}
                        scrollTo={scrollTo}
                    />
                )
            case FREE_TRANSPORTS_GRID :
                return (
                    <FreeTransportCard
                        {...props}
                        title={title}
                        id={id}
                        name={name}
                        form={form}
                        load={loadCard}
                        updateHistory={getHistory}
                        loading={loading}
                        settings={settings}
                        error={error}
                        onClose={handleClose}
                        onChangeForm={onChangeForm}
                        actionsFooter={getActionsFooter}
                        actionsHeader={getActionsHeader}
                        saveForm={saveOrEditForm}
                        isRegTC={isRegTC}
                        scrollTo={scrollTo}
                    />
                )
            case QUOTATIONS_GRID :
                return <GridCard
                    {...props}
                    form={form}
                    onChangeForm={onChangeForm}
                    name={name}
                    settings={settings}
                    title={title}
                    onClose={handleClose}
                    actionsFooter={getActionsFooter}
                    actionsHeader={getActionsHeader}
                    loading={loading}
                    error={error}
                    withHistory
                    allPlacesIsInformation
                    customBlockForSections={{
                        GeneralCargoInformation: ({handleChange}) => {
                            return <FormTable
                                label='cargoUnits'
                                name='cargoUnits'
                                form={form}
                                columnsConfig={arrayToColumnsConfig(cargoUnitsFields)}
                                columnsArray={cargoUnitsFields.map(c => c.name)}
                                onChange={handleChange}
                                error={error}
                                isDisabled={id && settings.cargoUnitsDisplay !== SETTINGS_TYPE_EDIT}
                            />
                        }
                    }}
                />
            default: return null;
        }
    }

    return (
        <React.Fragment key={name}>
            {loading
                ? <Loader active={true} size="huge" className="table-loader">
                    Loading
                </Loader>
                : typeCard(name)}
            <Confirm
                dimmer="blurring"
                open={confirmation.open}
                onCancel={confirmation.onCancel || closeConfirmation}
                cancelButton={t('cancelConfirm')}
                confirmButton={t('Yes')}
                onConfirm={confirmation.onConfirm}
                content={confirmation.content}
            />
            <PhoneNumberModal callbackSuccess={()=>loadCard(true)}/>
            <DeliveryTypeModal callbackSuccess={()=>loadCard(true)}/>
            <ClosedAuctionModal callbackSuccess={()=>loadCard(true)} name={name}/>
            <PaymentTermModal callbackSuccess={()=>loadCard(true)} name={name}/>
        </React.Fragment>
    );
};

export default Card;
