import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {
    closedAuctionModalSelector,
    errorSelector,
    saveTCsForAuction,
    saveLoadingSelector,
    setClosedAuctionModal,
} from "../../../../ducks/gridCard";
import SelectionField from "../../../paramsSetting/components/selectionField";
import {MULTISELECT_TYPE} from "../../../../constants/columnTypes";
import {Button} from "semantic-ui-react";
import RequiredFieldsModal from "../../../../components/RequiredFieldsModal";
import {getLookupRequest} from "../../../../ducks/lookup";

const ClosedAuctionModal = ({callbackSuccess, name}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const closedAuctionModal = useSelector(state => closedAuctionModalSelector(state));
    const loading = useSelector(state => saveLoadingSelector(state));
    const error = useSelector(state => errorSelector(state));

    const save = (data) => {
        dispatch(saveTCsForAuction({...data, name, id: closedAuctionModal.ids[0]}));
    };

    const onClose = () => {
        dispatch(setClosedAuctionModal({open: false}));
        callbackSuccess && callbackSuccess();
    }

    const fieldName = 'transportCompanies';

    useEffect(() => {
        closedAuctionModal.open && dispatch(
            getLookupRequest({
                name: fieldName,
                isForm: true,
            }),
        );
    }, [closedAuctionModal.open])

    const customFieldsTypeComponent = ({field, onChange, form}) => {
        const row = {
            value: form[field.name],
            isExcept: form.isExcept
        };

        return <SelectionField
            valueIsName={false}
            key={field.name}
            column={field}
            row={row}
            onChange={({name, value, isExcept}) => {
                onChange(null, {name: 'isExcept', value: isExcept});
                onChange(null, {name, value});
            }}
        />
    }

    return (
        <RequiredFieldsModal
            className='auction-tc-modal'
            open={closedAuctionModal.open}
            id={null}
            onClose={onClose}
            error={error}
            title={t('transportCompanies')}
            fields={[
                {
                    isRequired: false,
                    name: fieldName,
                    text: fieldName,
                    source: fieldName,
                    type: MULTISELECT_TYPE
                }
            ]}
            saveRequest={save}
            saveBtnName={t('SendButton')}
            loading={loading}
            customFieldsTypeComponent={customFieldsTypeComponent}
            btns={[
                <Button onClick={onClose}>
                    {t('CancelButton')}
                </Button>
            ]}
        />
    );
};

export default ClosedAuctionModal;