export default result => {
    const { data, headers } = result;
    let withQuotes = false;
    let headerLine = headers['content-disposition'] || '';
    let startFileNameIndex = headerLine.indexOf('filename=') + 9;
    if (headerLine[startFileNameIndex] === '"') {
        withQuotes = true;
        startFileNameIndex++;
    }
    let endFileNameIndex = headerLine.lastIndexOf(';') - (withQuotes ? 1 : 0);
    let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);

    const link = document.createElement('a');
    link.href = URL.createObjectURL(new Blob([data], { type: data.type }));
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
}

export const downloadBase64File = (contentBase64, fileName, type) => {
    const linkSource = `data:${type};base64,${contentBase64}`;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = fileName;
    downloadLink.click();
}

export const getFileExtension = (filename) => {
    const lastDotIndex = filename.lastIndexOf('.');
    return lastDotIndex !== -1 ? filename.slice(lastDotIndex + 1) : '';
}
