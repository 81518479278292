import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import DocWithEditor from '../../../../components/Documents/DocWithEditor';
import {
    clearDocuments,
    documentsSelector,
    getDocumentsRequest,
} from '../../../../ducks/documents';
import {isAuthSelector} from "../../../../ducks/login";

const Documents = ({gridName, cardId, isEditPermissions, updateHistory, typeId = null, isBtn, uploadFile, types, id, btnIcon, btnText, btnPopupText, btnDisabledPopupText, btnPopupPosition, onlyOne, getDocs, formats}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const isAuth = useSelector(state => isAuthSelector(state));

    const getDocuments = () => {
        !getDocs && cardId && !isBtn && dispatch(getDocumentsRequest({gridName, cardId, noAuth: !isAuth}));
    };

    useEffect(() => {
        getDocuments();
        return () => {
            (!isBtn && !getDocs) && dispatch(clearDocuments());
        };
    }, []);

    const documents = useSelector(state => documentsSelector(state, typeId));

    return (
        <div className={!isBtn ? "flex-container tabs-card" : 'doc-btn'}>
            {documents.map((document, index) => (
                <DocWithEditor
                    formats={formats}
                    offGetTypes={getDocs}
                    typeId={typeId}
                    key={document.fileId}
                    gridName={gridName}
                    cardId={cardId}
                    document={document}
                    updateHistory={updateHistory}
                    isEditPermissions={isEditPermissions}
                    getDocuments={getDocs ? getDocs : getDocuments}
                    titleText={t('Edit document')}
                    okButtonText={t('SaveButton')}
                    isAuth={isAuth}
                    isBtn={isBtn}
                    uploadFile={uploadFile}
                    types={types}
                    id={id}
                />
            ))}
            {(!onlyOne || !documents.length) && <DocWithEditor
                formats={formats}
                offGetTypes={getDocs}
                typeId={typeId}
                gridName={gridName}
                cardId={cardId}
                updateHistory={updateHistory}
                isEditPermissions={isEditPermissions}
                getDocuments={getDocs ? getDocs : getDocuments}
                titleText={t('Add document')}
                okButtonText={t('AddButton')}
                isAuth={isAuth}
                isBtn={isBtn}
                uploadFile={uploadFile}
                types={types}
                id={id}
                btnIcon={btnIcon}
                btnText={btnText}
                btnDisabledPopupText={btnDisabledPopupText}
                btnPopupText={btnPopupText}
                btnPopupPosition={btnPopupPosition}
            />}
        </div>
    );
};

export default Documents;
