export const isReadOnly = (isReadOnlyFields, form, columnsConfig, c, clear) => {
    const readOnlyData = isReadOnlyFields[c];
    const readOnlyFieldForName = readOnlyData && readOnlyData[0];
    const valueOfField = form[readOnlyFieldForName];
    const arrayOfNotReadOnlyValues = readOnlyData && readOnlyData[1];

    if (readOnlyData && form[c] && arrayOfNotReadOnlyValues && !arrayOfNotReadOnlyValues.includes((valueOfField || {}).value)) {
        clear && clear(c);
    }

    return (readOnlyData && ((!(arrayOfNotReadOnlyValues || []).length && valueOfField) || ((arrayOfNotReadOnlyValues || []).length && !arrayOfNotReadOnlyValues.includes((valueOfField || {}).value))))
        || columnsConfig[c].isReadOnly;
}